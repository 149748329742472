.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body, html, .App, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.background-comp {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.overlay img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}

.top-left img {
  position: absolute;
  top: 0;
  left: 0;
}

.bottom img {
  position: absolute;
  bottom: 0;
}

.depth-glow {
  filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.15));
}
#site-title {
  color: white;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  font-size: 1.1rem;
  letter-spacing: 1.6rem;
  white-space: nowrap;
}

.margin-5perc {
  margin-left: 5%;
  margin-top: 5%;
}
.max-size-20perc img {
  max-width: 20%;
}
.footer-graphic {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../public/static/media/forest-trees-silhouette.png);
  background-position: center bottom;
  background-size: 100% auto;
  background-repeat: no-repeat; 
}